import { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import stampImage from "../CAS/stamp.png";
import { useSelector } from "react-redux";
import { language } from "../utils/part-1lan";
import { FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import apiRequest from "../utils/request";
import { toast } from "react-toastify";


const StampPlacer4 = ({ pdfUrl, setStampPlacementModal, outgoingletter,reloadData,setLoading,setServerError,type }) => {
  const {id} = useParams()
  const token =sessionStorage.getItem("tID")
  const translationState = useSelector((state) => state.translation);
  const [pdf, setPdf] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [stampPositions, setStampPositions] = useState([]);
  const [dragging, setDragging] = useState(false);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [reloadKey, setReloadKey] = useState(0); 
  
  const stampWidth = 110;
  const stampHeight = 110;

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js`;

    const loadPdf = async () => {
      const loadingTask = pdfjsLib?.getDocument(pdfUrl);
      const loadedPdf = await loadingTask?.promise;
      if (!loadedPdf) return;
      setPdf(loadedPdf);
      renderPage(loadedPdf, pageNumber);
    };

    loadPdf();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadKey,pdfUrl,pageNumber]);

const renderPage = async (pdf, pageNum) => {
    const page = await pdf?.getPage(pageNum);
    const canvas = canvasRef?.current;
    const context = canvas?.getContext("2d");
    const viewport = page?.getViewport({ scale: 1 });

    canvas.width = viewport?.width;
    canvas.height = viewport?.height;
    context.clearRect(0, 0, canvas?.width, canvas?.height); 

    try {
      await page?.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;
    } catch (error) {
      console.error("");
    }  
  };

  const handleMouseDown = () => {
    setDragging(true);
  };

const handleMouseMove = (e) => {
    if (!dragging || !canvasRef.current || !containerRef.current) return;

    const canvas = canvasRef?.current;
    const rect = canvas?.getBoundingClientRect();

    const newX = Math?.min(
      Math?.max(e.clientX - rect?.left - stampWidth / 2, 0),
      canvas?.width - stampWidth
    );
    const newY = Math?.min(
      Math?.max(e?.clientY - rect?.top - stampHeight / 2, 0),
      canvas?.height - stampHeight
    );

    setStampPositions((prev) => {
      const existingIndex = prev?.findIndex((pos) => pos?.page === pageNumber);
      if (existingIndex >= 0) {
        const updatedPositions = [...prev];
        updatedPositions[existingIndex] = {
          page: pageNumber,
          x: newX,
          y: newY,
        };
        return updatedPositions;
      } else {
        return [...prev, { page: pageNumber, x: newX, y: newY }];
      }
    });
  };



  const handleMouseUp = () => {
    setDragging(false);
  };

  const handlePageChange = (newPage) => {
    if (pdf && newPage > 0 && newPage <= pdf?.numPages) {
      setPageNumber(newPage);
      renderPage(pdf, newPage);
    }
  };

  const removeStampFromPage = (pageNum) => {
    setStampPositions((prev) => prev?.filter((pos) => pos?.page !== pageNum));
    if (pageNum === pageNumber) {
      renderPage(pdf, pageNum);
     
    }
 
  };

  const placeStampAtDefaultPosition = () => {
    const canvas = canvasRef?.current;
    if (!canvas) return;

    const centerX = (canvas?.width - stampWidth) / 2;
    const centerY = (canvas?.height - stampHeight) / 2;

    setStampPositions((prev) => {
      const existingIndex = prev?.findIndex((pos) => pos?.page === pageNumber);
      if (existingIndex >= 0) {
        const updatedPositions = [...prev];
        updatedPositions[existingIndex] = {
          page: pageNumber,
          x: centerX,
          y: centerY,
        };
        return updatedPositions;
      } else {
        return [...prev, { page: pageNumber, x: centerX, y: centerY }];
      }
    });

    renderPage(pdf, pageNumber);
  };

  const handleVerifyOutgoingLetter = async () => {
    try {
      setLoading(true);
      await apiRequest
        .put(
          `/outgoing_ltr_api/verfy_output_ltr/${id}`,
          {
            cords:JSON.stringify(stampPositions)
          },
          {
            headers: {
              get_vrfyoutputltr_api:
                process.env.REACT_APP_GET_VRFYOUTPUTLTR_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          translationState?.lan === "En"
            ? toast.success(res?.data?.Message_en)
            : toast.success(res?.data?.Message_am);
            setStampPlacementModal(false)
            reloadData();
         
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.status === 500) {
            setServerError(true);
          }
          translationState?.lan === "En"
            ? toast.error(error?.response?.data?.Message_en)
            : toast.error(error?.response?.data?.Message_am);
        });
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  };

  const handleVerifyInternalLetter = async () => {
    try {
    
     
        await apiRequest
        .put(
          `/internal_ltr_api/verfy_internal_ltr/${id}`,
          {
            cords:JSON.stringify(stampPositions)
          },
          {
            headers: {
              get_vrfyintltr_api:
                process.env.REACT_APP_GET_VRFYINTLTR_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          translationState?.lan === "En" && toast.success(res?.data?.Message_en);
          translationState?.lan === "Am" && toast.success(res?.data?.Message_am);
          setStampPlacementModal(false)
          reloadData();
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.status === 500) {
            setServerError(true);
          }

          translationState?.lan === "En" &&
            toast.error(error?.response?.data?.Message_en);
        });

      
    
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  };

  

  const handleReload = () => {
    setPdf(null);  
    setReloadKey(prev => prev + 1); 
};
  

  

  return (
    
    <div className="w-full flex flex-col gap-4">
      <div className="w-[90%] mx-auto my-[20px] flex justify-between items-center">
        <span className="text-[#0C73B8] font-bold text-[20px]">
          {translationState?.lan === "En" && language?.stampPlacement[0]}
          {translationState?.lan === "Am" && language?.stampPlacement[1]}{" "}
        </span>

        <div
          onClick={() => setStampPlacementModal(false)}
          className="p-2 bg-[#0C73B8] rounded-[10px] cursor-pointer"
        >
          <FaTimes className="text-white" />
        </div>
      </div>
      <div className="w-[90%] mx-auto h-[1px] bg-gray-300" />
      <div className="w-[90%] mx-auto my-[10px] flex justify-between items-center gap-4">
              
            <div className="flex items-center gap-4">
            <button 
                onClick={handleReload} 
                className="py-2 px-4 bg-[#0C73B8] text-[12px] text-white cursor-pointer rounded-[10px]"
            >
               {translationState?.lan === "En" && language?.reloadPDF[0]}
               {translationState?.lan === "Am" && language?.reloadPDF[1]}{" "}
            </button>
            <button
               className="py-2 px-4 bg-[#0C73B8] text-[12px] text-white cursor-pointer rounded-[10px]"
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
               {translationState?.lan === "En" && language?.previous[0]}
               {translationState?.lan === "Am" && language?.previous[1]}{" "}
              </button>
              <span className="font-bold text-[#0C73B8]"> {translationState?.lan === "En" && language?.page[0]}
              {translationState?.lan === "Am" && language?.page[1]} {pageNumber} / {pdf?.numPages}</span>
              <button
               className="py-2 px-4 bg-[#0C73B8] text-[12px] text-white cursor-pointer rounded-[10px]"
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pdf && pageNumber === pdf?.numPages}
              >
              {translationState?.lan === "En" && language?.next[0]}
              {translationState?.lan === "Am" && language?.next[1]}{" "}
              </button>
            </div>
             <div className="flex items-center gap-4">
             <button
                className="py-2 px-4 bg-[#0C73B8] text-[12px] text-white cursor-pointer rounded-[10px]"
                onClick={placeStampAtDefaultPosition}
                disabled={stampPositions?.some(
                  (pos) => pos?.page === pageNumber
                )}
              >
               {translationState?.lan === "En" && language?.placeStampOnPage[0]}
               {translationState?.lan === "Am" && language?.placeStampOnPage[1]} {pageNumber}
              </button>
             <button
                className="py-2 px-4 bg-red-600 text-[12px] text-white cursor-pointer rounded-[10px]"
                onClick={() => removeStampFromPage(pageNumber)}
                disabled={
                  !stampPositions?.some((pos) => pos?.page === pageNumber)
                }
              >
                 {translationState?.lan === "En" && language?.removeStampFromPage[0]}
                 {translationState?.lan === "Am" && language?.removeStampFromPage[1]} {pageNumber}
              </button>
           
              {type==="outgoing" && stampPositions?.length > 0 && outgoingletter?.status === "output" && (
              <button
                onClick={handleVerifyOutgoingLetter}
                className="py-2 px-4 text-[12px] text-white bg-[#0C73B8] rounded-[20px] font-bold max-lg2:text-[10px]"
              >
                {translationState?.lan === "En" && language?.stamp[0]}
                {translationState?.lan === "Am" && language?.stamp[1]}
              </button>
            )}
             {type==="internal" && stampPositions?.length > 0 && outgoingletter?.status === "output" && (
              <button
                onClick={handleVerifyInternalLetter}
                className="py-2 px-4 text-[12px] text-white bg-[#0C73B8] rounded-[20px] font-bold max-lg2:text-[10px]"
              >
                {translationState?.lan === "En" && language?.stamp[0]}
                {translationState?.lan === "Am" && language?.stamp[1]}
              </button>
            )}
             </div>
              
            
          </div>

          <div className="w-[90%] mx-auto h-[1px] bg-gray-300" />

    <div className="w-[70%] mx-auto flex justify-center items-center">
    <div
    
        ref={containerRef}
        style={{ position: "relative", display: "inline-block", }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <canvas ref={canvasRef}  className="border border-gray-300 rounded-md" />

        {stampPositions?.some((pos) => pos?.page === pageNumber) && (
          <img
            src={stampImage}
            alt="stamp"
            style={{
              position: "absolute",
              left: `${
                stampPositions?.find((pos) => pos?.page === pageNumber)?.x || 0
              }px`,
              top: `${
                stampPositions?.find((pos) => pos?.page === pageNumber)?.y || 0
              }px`,
              width: `${stampWidth}px`,
              height: `${stampHeight}px`,
              cursor: "grab",
              display: dragging ? "none" : "block",
            }}
            onMouseDown={handleMouseDown}
          />
        )}

       
      </div>
    </div>
    </div>
  );
};

export default StampPlacer4;
