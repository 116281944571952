export const language ={
    companyName:["Addis Ababa Housing Development Corporation","አዲስ አበባ ቤቶች ልማት ኮርፖሬሽን","","","",""],
    welcomeMessage:["Welcome To Addis Ababa Housing Development Corporation","እንኳን ወደ አዲስ አበባ ቤቶች ልማት ኮርፖሬሽን በደህና መጡ","","","",""],
    customer:["Customer","ደንበኛ","","","",""],
    checkStatus:["Check status","ሁኔታን ያረጋግጡ","","","",""],
    makeAccusation:["Make Accusation","ክስ ያቅርቡ","","","",""],
    name:["Name","ስም","","","",""],
    subject:["Subject","ርዕሰ ጉዳይ","","","",""],
    phone:["Phone","ስልክ","","","",""],
    description:["Description","መግለጫ","","","",""],
    attachement:["Attachment","አባሪ","","","",""],
    uploadAttachment:["Upload attachment","አባሪ ይጫኑ","","","",""],
    cancel:["Cancel","ሰርዝ","","","",""],
    submit:["Submit","አስገባ","","","",""],
    login:["Login","ግባ","","","",""],
    register:["Register","ይመዝገቡ","","",""],
    bannerTitle: ["Welcome To Addis Ababa Housing Development Corporation","እንኳን ወደ አዲስ አበባ ቤቶች ልማት ኮርፖሬሽን በደህና መጡ","","","",""],
    bannerSubTitle:["Customer Service","የደንበኞች አገልግሎት","","","",""],
    services:["Services","አገልግሎቶች","","","",""],
    division1:["Land Design and Infrastructure Development","የመሬት ዲዛይን እና የመሠረተ ልማት ግንባታ","","","",""],
    division2:["House Construction","የቤት ግንባታ","","",""],
    division3:["House Transfer and Construction Finance","የቤት ማስተላለፍ እና የግንባታ ፋይናንስ","","","",""],
    division4:["Corporate Service","የኮርፖሬት አገልግሎት","","","",""],
    logintoyourAccount:["Login to your account","ወደ መለያዎ ይግቡ","","","",""],
    usernamePlaceholder:["Username","የተጠቃሚ ስም","","","",""],
    passwordPlaceholder:["password","የይለፍ ቃል","","","",""],
    forgotPasswordLink:["forgot your password","የሚስጥራዊውን ቁጥር ረስተዋል","","","",""],
    loginButton:["Sign In","ይግቡ","","","",""],
    createAnAccount:["Create An Account","መለያ ፍጠር","","","",""],
    firstname:["First Name","የመጀመሪያ ስም","","","",""],
    middlename:["Middle Name","የአባት ስም","","","",""],
    lastname:["Last Name","የአያት ስም","","","",""],
    preferredLanguage:["Preferred Language","የቋንቋ ምርጫ","","","",""],
    username:["Username","የተጠቃሚ ስም","","","",""],
    email:["Email","ኢሜይል","","","",""],
    subCity:["Sub-city","ክፍለ ከተማ","","","",""],
    woreda:["Woreda","ወረዳ","","","",""],
    houseNumber:["House Number","የቤት ቁጥር","","","",""],
    housePhoneNumber:["House Phone Number","የቤት ስልክ ቁጥር","","","",""],
    gender:["Gender","ጾታ","","","",""],
    selectGender:["Select Gender","ጾታ ይምረጡ","","","",""],
    male:["Male","ወንድ ","","","",""],
    female:["Female","ሴት","","","",""],
    password:["Password","የይለፍ ቃል","","","",""],
    confirmPassword:["Confirm Password","የይለፍ ቃል አረጋግጥ","","","",""],
    uploadProfileImage:["Upload Profile Image","የመገለጫ ምስል አስቀምጥ","","","",""],
    verifyYourEmail:["Verify your Email","ኢሜልዎን ያረጋግጡ","","","",""],
    weHaveSentACode:["We have sent a code to your email","ወደ ኢሜልዎ ኮድ ልከናል","","","",""],
    verifyEmail:["Verify Email","ኢሜል ያረጋግጡ","","","",""],
    didntrecievecode:["Didn't Recieve code","ኮድ አልተቀበለም","","","",""],
    resend:["Resend","እንደገና ላክ","","","",""],
    customerInformation:["Customer Information","የደንበኛ መረጃ","","","",""],
    print:["Print","አትም","","","",""],
    customerDashboard:["Customer Dashboard","የደንበኛ ዳሽቦርድ","","","",""],
    toManyRequests:["Too many attempts. Please try again later.","ተደጋጋሚ ሙከራ አድርገዋል። እባክዎ ትንሽ ኮይተው ይሞክሩ።","","","","",""],
    noAvailableCase:["No available cases","ምንም አይነት ጉዳይ አላመለከቱም","","","",""],
    cases:["Cases","ጉዳዮች","","","",""],
    dashboard:["Dashboard","ዳሽቦርድ","","","",""],
    applyCase:["Apply Case","ጉዳይ ማመልከቻ","","","",""],
    caseStatus:["Case Status","የጉዳይ ሁኔታ","","","",""],
    caseSummary:["Case Summary","የጉዳይ ማጠቃለያ","","","",""],
    caseNumber:["Case Number","የጉዳይ ቁጥር","","","",""],
    status:["Status","ሁኔታ","","","",""],
    windowService:["Window Services","የመስኮት አገልግሎቶች","","","",""],
    forgotPassword:["Forgot Password","የይለፍ ቁልፉን ረስተዋል","","","",""],
    resetPassword:["Reset Password","የይለፍ ቃል ይቀየሩ","","","","",""],
    enteryourUsername:["Enter your username and password to continue","ለመቀጠል የእርስዎን የመጠቀሚያ ስም እና የይለፍ ቃል ያስገቡ","","","",""],
    createCase:["Create Case","ጉዳይ ይክፈቱ","","","",""],
    accountSettings:["Account Settings","መለያ ማደራጃ","","","",""],
    logout:["Logout","ውጣ","","","",""],
    Hello:["Hello","ሰላም","","","","",""],
    pending:["Pending","በመጠባበቅ ላይ","","","",""],
    ongoing:["Ongoing","በመካሄድ ላይ","","","","",""],
    processing:["Processing","ፕሮሰሲኢንግ","","","",""],
    responded:["Responded","ተመልሷል","","","",""],
    verfied:["Verified","የተረጋገጠ","","","",""],
    rejected:["Rejected","ተቀባይነት አላገኘም","","","",""],
    caseInformation:["Case Information","የጉዳይ መረጃ","","","",""],
    recentCases:["Recent Cases","የቅርብ ጊዜ ጉዳዮች","","","",""],
    selectDivision:["Select Division","ዘርፍ ይምረጡ","","","",""],
    selectCaseRequest:["Select Case Request","ቅጽ ይምረጡ","","","",""],
    selectRelatedCaseNumber:["Select Related Case Number","ተዛማጅ የጉዳይ ቁጥር ይምረጡ","","","",""],
    apply:["Apply","ያመልክቱ","","","",""],
    noCases:["You have no cases","ምንም ዓይነት የገባ ጉዳይ የለም","","","",""],
    processingStartDate:["Processing Start Date","ፕሮሰስ መደረግ የጀመረበት ቀን","","","",""],
    scheduleInformation:["Schedule Information","የቀጠሮ መረጃ","","","",""],
    filledQuestions:["filled Questions","የተሞሉ ጥያቄዎች","","","",""],
    responseAttachment:["Response Attachment","ምላሽ አባሪ","","","",""],
    respondedBy:["Responded By","ምላሽ የሰጠው በ","","","",""],
    active:["Active","አክቲቭ","","","",""],
    inactive:["Inactive","ኢን-አክቲቭ","","","",""],
    ethicsManaement:["Ethics Management","የስነምግባር አስተዳደር","","","",""],
    toStayConnected:["To stay connected login back to your account with your credentials","ለመቀጠል የመጠቀሚያ ስም እና የይለፍ ቃል ያስገቡ","","","",""],
    accusations:["Accusations","ጥቆማ","","","",""],
    totalAccusations:["Total Accusations","ጠቅላላ ክሶች","","","",""],
    accusedDeprtment:["Accused department","የተከሰሰው ክፍል","","","",""],
    recievedOn:["Recieved On","ተቀብሏል","","",""],
    personalInformation:["Personal Information","የግል መረጃ","","","",""],
    emailAddress:["Email Address","የኢሜል አድራሻ","","","",""],
    back:["Back","ተመለስ","","","",""],
    retypePassword:["Re-type password","የይለፍ ቃል ድጋሜ ያስገቡ","","","","","",""],
    searchByname:["Search by name","በስም ፈልግ","","","",""],
    searchByPhone:["Search by phone","በስልክ ይፈልጉ","","","","",""],
    searchByCaseNumber:["Search by case number","በጉዳይ ቁጥር ይፈልጉ","","","",""],
    latest:["Latest","የቅርብ ጊዜ","","","",""],
    oldest:["Oldest","የቆየ","","","",""],
    rateService:["Are you statisfied with the service ?","በአገልግሎቱ ምን ያህል ረክተዋል","","","",""],
    SomethingWentWrong:["Something went wrong","የሆነ ስህተት ተከስቷል","","","",""],
    errorConnectingtoServer:["It seems like there was an error connecting to the server. Please try again later. Thank you for your patience."," ከአገልጋዩ ጋር መገናኘት ላይ ስህተት ያለ ይመስላል። እባክዎ ቆየት ብለው ይሞክሩ፤ ስለትግስትዎ አናመሰግናለን","","","",""],
    reload:["Reload","ዳግም ጫን","","","",""],
    pageNotFound:["Page Not Found","ገጹ አልተገኘም","","","",""],
    myProfile:["My Profile","የኔ ገጽታ","","","",""],
    weCoudldNotFindThePage:["We couldn't find the page you are looking for.","የሚፈልጉትን ገጽ ማግኘት አልቻልንም","","","",""],
    passwordMatch:["Password and confirm password don't match","የይለፍ ቃል እና የይለፍ ቃል ያረጋግጡ አይዛመዱም","","","",""],
    archvial:["Archival","መዝገብ ቤት","","","",""],
    internaLetter:["Internal Letter","የውስጥ ደብዳቤ","","","",""],
    externalLetter:["External Letter","የውጭ ደብዳቤ","","","",""],
    createLetter:["Create Incoming Letter","ገቢ ደብዳቤ ይመዝግቡ","","","",""],
    createPresystemletter:["Create letter","ደብዳቤ ይመዝግቡ"],
    letterType:["Letter type","የደብዳቤ አይነት","","","",""],
    letterNumber:["Letter Number (NIMMERA)","የደብዳቤው ቁጥር (ንምራ)","","","",""],
    letterNumbers:["Letter Number","የደብዳቤው ቁጥር","","","",""],
    archive:["Archive","መዝግብ"],
    dateofSent:["Date of letter sent","የተላከ ደብዳቤ ቀን","","","",""],
    sentfrom:["Sent from","የተላከው ከ","","","",""],
    sentTo:["Sent To","የተላከው ለ","","","",""],
    letter:["Letter","ደብዳቤው","","","",""],
    uploadLetter:["Upload Letter","ደብዳቤ ጫን","","","",""],
    from:["From","ከ","","","",""],
    to:["To","ለ","","","",""],
    remark:["Reamrk","አስተያየት","","","",""],
    createdBy:["Created By","የተመዘገበው በ","","","",""],
    archivedIn:["Archived In","ውስጥ ተመዝግቧል","","","",""],
    createdDate:["Created Date","የገባበት ቀን","","","",""],
    createdTime:["Created Time","የገባበት ጊዘ","","","",""],
    cc:["Cc","ግልባጭ","","","",""],
    letterContent:["Letter Content","የደብዳቤ ይዘት","","","",""],
    stampedDate:["Stamped Date","የታተመበት ቀን","","","",""],
    stampedTime:["Stamped Time","የታተመበት ሰዓት","","","",""],
    stampedBy:["Stamped By","ማህተም የተደረገው በ","","","",""],
    submittedDate:["Submited Date","የቀረበበት ቀን","","","",""],
    division:["Division","ዘርፍ","","","",""],
    relatedCaseNumber:["Related Case Number","ተዛማጅ የጉዳይ ቁጥር","","","",""],
    customerName: ["Customer Name","የደንበኛ ስም","","","",""],
    caseRequest:["Case Request","የጉዳይ ጥያቄ","","","",""],
    scheduledDate:["Scheduled Date","የቀጠሮ ቀን","","","",""],
    scheduledTime:["Scheduled Time","የቀጠሮ ሰዓት","","","",""],
    extendedDate:["Extended Date","የተራዘመ ቀን","","","",""],
    extendedTime:["Extended Time","የተራዘመ ሰዓት","","","",""],
    extendedBy:["Extended By","የተራዘመው በ","","","",""],
    scheduledBy:["Scheduled By","ቀመሮ የተያዘለት በ ","","","",""],
    ongoingDate:["Ongoing Date","ቀጣይነት ያለው ቀን","","","",""],
    respondedDate:["Responded Date","ምላሽ የተሰጠበት ቀን","","","",""],
    verifiedDate:["Verified Date","የተረጋገጠ ቀን","","","",""],
    verfiedBy:["Verified By","የተረጋገጠው በ","","","",""],
    finalFileAttachment:["Final file attachment","የመጨረሻ አባሪ","","","",""],
    rejectedDate:["Rejected Date","ውድቅ የተደረገበት ቀን","","","",""],
    reasonOfRejection:["Reason of Rejection","ውድቅ የተደረገበት ምክንያት","","","",""],
    formFilledIn:["Form filled in","ቅጽ ተሞልቷል","","","",""],
    late:["Late","ረፍዷል","","","",""],
    responseJustification:["Response Justification","ምላሽ ጽድቅ","","","",""],
    rejectionJustification:["Rejection Justification","ውድቅ ማድረጊያ","","","",""],
    stamp:["Stamp","አትም","","","",""],
    archivalCategory:["Archival Category","የመዝገብ ምድብ","","","",""],
    createCategory:["Create Category","ምድብ ፍጠር","","","",""],
    createCategoryEnglish:["Create category english","የመዝገብ ምድብ (እንግሊዘኛ)"],
    createCategoryAmharic:["Create category amharic","የመዝገብ ምድብ (አማርኛ)"],
    cateogryName:["Category Name","የምድብ ስም","","","","",""],
    creatorName:["Creator Name","የፈጣሪ ስም","","","",""],
    creatorusername:["Creator Username","የፈጣሪ የተጠቃሚ ስም","","","",""],
    cateogoryStatus:["Category Status","የምድብ ሁኔታ","","","",""],
    edit:["Edit","አርትዕ","","","",""],
    archivalCategoryName:["Archival Category Name","የማህደር ምድብ ስም","","","",""],
    it:["IT","አይቲ","","","","",""],
    enteryouremail:["Enter your email","የእርስዎን ኢሜይል ያስገቡ","","","",""],
    getResetLink:["Get reset link","የይለፍ ቃል መቀየሪያ መስፈንጠሪያ ያግኙ","","","",""],
    backToLogin:["Back to login","ወደ መግቢያ ተመለስ"],
    hello:["Hello","ሀሎ "],
    selam:["Selam","ሰላም"],
    accounts:["Accounts","መለያ"],
    orgStructure:["Org. Structure","ኦርግ መዋቅር"],
    caseList:["Case List","የጉዳይ ዝርዝር"],
    questions:["Questions","ጥያቄዎች"],
    administrators:["Adminstrators","አስተዳዳሪዎች"],
    archivalUsers:["Archival Users","የመዝገቤት ተጠቃሚዎች"],
    ethicsUsers:["Ethics Users","የስነምግባር ተጠቃሚዎች"],
    customers:["Customers","ደንበኞች"],
    profile:["Profile","መገለጫ"],
    fullName:["Full Name","ሙሉ ስም"],
    level:["Level","ደረጃ"],
    action:["Action","ድርጊት"],
    phoneNumber:["Phone Number","ስልክ ቁጥር"],
    typeofPosition:["Type of Position","የስራ ክፍል"],
    profilePicture:["Profile Picture","የመገለጫ ሥዕል"],
    titer:["Titer","ቲተር"],
    signiture:["Signiture","ፊርማ"],
    create:["Create","ፍጠር"],
    currentPicture:["Current Picture","የአሁኑ ሥዕል"],
    createAdministrator:["Create Administrator","አስተዳዳሪ ፍጠር"],
    searchByUsername:["Search by username","በተጠቃሚ ስም ፈልግ"],
    searchByName:["Search by name","በስም ፈልግ"],
    searchByEmail:["Search by email","በኢሜል ይፈልጉ"],
    searchByLevel:["Search by level","በደረጃ ይፈልጉ"],
    searchByStatus:["Search by status","በሁኔታ ይፈልጉ"],
    searchByCaseList:["Search by case list","በጉዳይ ዝርዝር ይፈልጉ"],
    searchByDivisionName:["Search by division name","በዘርፍ ስም ይፈልጉ"],
    searchByDirectorateName:["Search by directorate name","በዳይሬክቶሬት ስም ይፈልጉ"],
    searchByTeamName:["Search by team name","በቡድን ስም ይፈልጉ"],
    divisions:["Divisions","ዘርፎች"],
    divisionNameEN:["Division name in english","የዘርፍ ስም በእንግሊዝኛ"],
    divisionNameAM:["Division name in amharic","የዘርፍ ስም በአማርኛ"],
    divisionNameOR:["Division name in oromo","የዘርፍ ስም በኦሮምኛ"],
    divisionNameTI:["Division name in tigrgna","የዘርፍ ስም በትግርኛ"],
    divisionNameAF:["Division name in afar","የዘርፍ ስም በአፋርኛ"],
    divisionNameSM:["Division name in somali","የዘርፍ ስም በሶማልኛ"],
    addnewDivision:["Add new division","አዲስ ዘርፍ ጨምር"],
    directorate:["Directorate","ዳይሬክቶሬት"],
    directorates:["Directorates","ዳይሬክቶሬቶች"],
    addNewDirectorate:["Add new directorate","አዲስ ዳይሬክቶሬት ያክሉ"],
    directoratenNameEN:["Directorate name in english","የዳይሬክቶሬት  ስም በእንግሊዝኛ"],
    directorateNameAM:["Directorate name in amharic","የዳይሬክቶሬት ስም በአማርኛ "],
    directorateNameOR:["Directorate name in oromo","የዳይሬክቶሬት ስም በኦሮምኛ"],
    directorateNameTI:["Directorate name in tigrgna","የዳይሬክቶሬት ስም በትግርኛ"],
    directorateNameAF:["Directorate name in afar","የዳይሬክቶሬት ስም በአፋርኛ"],
    directorateNameSM:["Directorate name in somali","የዳይሬክቶሬት ስም በሶማልኛ"],
    addMembersToDirectorate:["Add Members to directorate","አባላትን ወደ ዳይሬክቶሬት ያክሉ"],
    alreadyMembers:["Already Members","ቀድሞውኑ አባላት"],
    alreadyMembersToDirectorate:["Already members to directorate","ቀድሞውኑ አባላት ወደ ዳይሬክቶሬት"],
    removeFromDirectorate:["Remove from directorate","ከዳይሬክቶሬት አስወጣ"],
    teams:["Teams","ቡድኖች"],
    addNewTeam:["Add new team","አዲስ ቡድን ያክሉ"],
    addMembersToTeam:["Add members to team","አባላትን ወደ ቡድን ያክሉ"],
    paraph:["Paraph","ፓራፍ"],
    addNewparaph:["Add new paraph","አዲስ ፓራፍ ጨምር"],
    currentParaphs:["Current paraphs","አሁን ያሉት ፓራፍ"],
    adminInfo:["Administrator's Information","የአስተዳዳሪ መረጃ"],
    manager:["Manager","አስተዳዳሪ "],
    memebers:["Members","አባላት"],
    joinedIn:["Joined In","ተቀላቅሏል"],
    changeStatus:["Change Status","ሁኔታን ቀይር"],
    update:["Update","አዘምን"],
    english:["English","እንግሊዝኛ"],
    amharic:["Amharic","አማርኛ"],
    tigrgna:["Tigrgna","ትግርኛ"],
    oromo:["Oromo","ኦሮምኛ"],
    afar:["Afar","አፋርኛ"],
    somali:["Somali","ሶማሊኛ"],
    timeLimit:["Time Limit","የጊዜ ገደብ"],
    nameEN:["name in english","ስም በእንግሊዝኛ"],
    nameAM:["name in amharic","ስም በአማርኛ"],
    nameOR:["name in oromo","ስም በኦሮምኛ"],
    nameTI:["name in tigrgna","ስም በትግርኛ"],
    nameAF:["name in afar","ስም በአፋርኛ"],
    nameSM:["name in somali","ስም በሶማልኛ"],
    answeredBy:["Answered By","መልስ በ"],
    selectQuestions:["Select Questions","ጥያቄዎችን ይምረጡ"],
    selectedQuestions:["Selected Questions","የተመረጡ ጥያቄዎች"],
    createAQuestion:["Create a question","ጥያቄ ፍጠር"],
    editQuestion:["Edit Question","ጥያቄን ያርትዑ"],
    typeOfData:["Type of data","የመረጃ አይነት"],
    questionRequired:["Is this question required","ይህ ጥያቄ ያስፈልጋል"],
    typeOfInput:["Type of input","የማስገቢያ አይነት"],
    selectableValues:["Does this question have selectable values","ይህ ጥያቄ ሊመረጡ የሚችሉ እሴቶች አሉት?"],
    yes:["Yes","አዎ"],
    no:["No","አይ"],
    text:["Text","ጽሑፍ"],
    number:["Number","ቁጥር"],
    noOfSelectables:["How many selectables","ምን ያህል ሊመረጡ የሚችሉ"],
    expectedValue:["Expected value","የሚጠበቀው ዋጋ"],
    PageNotFound:["Page not found","ገጹ አልተገኘም"],
    notFoundDescription:["We couldn't find the page you are looking for.","የሚፈልጉትን ገጽ ማግኘት አልቻልንም።"],
    errorMessage:["Something went wrong","የሆነ ስህተት ተከስቷል"],
    choice:["Choice","ምርጫ"],
    errorDescription:["It seems like there was an error connecting to the server. Please try again later. Thank you for your patience","ከአገልጋዩ ጋር መገናኘት ላይ ስህተት ያለ ይመስላል። እባክዎ ቆየት ብለው ይሞክሩ::ስለትግስትዎ አናመሰግናለን"],
    caseRequests:["Case Requests","የጉዳይ ዝርዝሮች"],
    answerBy:["Answer By","መልስ በ"],
    timelimit:["Timelimit","የጊዜ ገደብ"],
    enteryourCred:["Enter your credentials to continue","ለመቀጠል የመጠቀሚያ ስም አና የይለፍ ቃል  ያስገቡ"],
    forwardCases:["Forward Cases","የተመሩ ጉዳዮች"],
    repliedCases:["Replied Cases","የተመለሱ ጉዳዮች"],
    forwardedComplaints:["Forwarded Complaints","የተመሩ ቅሬታዎች"],
    repliedComplaints:["Replied Complaints","የተመለሱ ቅሬታዎች"],
    forwardedLetters:["Forwarded Letters","የተመሩ ደብዳቤዎች "],
    repliedLetters:["Replied Letters","የተመለሱ ደብዳቤዎች"],
    archivals:["Archivals","ማህደሮች"],
    verifyCases:["Verify cases","ጉዳይ ማረጋገጫ","","","",""],
    totalCases:["Total Cases","ጠቅላላ ጉዳዮች","","","",""],
    pendingCases:["Pending Cases","አዲስ የገባ ጉዳዮች","","","",""],
    processingCases:["Processing Cases","በሂደት ላይ ያሉ ጉዳዮች","","","",""],
    respondedCases:["Responded Cases","የተመለሱ ጉዳዮች","","","",""],
    rejectedCases:["Rejected Cases","ውድቅ የተደረጉ ጉዳዮች","","","",""],
    totalComplaints:["Total Complaints","ጠቅላላ ቅሬታዎች"],
    totalLetters:["Total Letters","ጠቅላላ ደብዳቤዎች"],
    internalMemo:["Internal Memo","ውስጣዊ ሜሞ"],
    complaintSummary:["Complaint Summary","የቅሬታ ማጠቃለያ"],
    divisionName:["Division Name","የዘርፍ ስም"],
    createdAt:["Created At","የተፈጠረው ቀን"],
    divisionInfo:["Division Information","የዘርፍ መረጃ"],
    position:["Position","ክፍል"],
    canForward:["Can Forward","ለማ መምራት"],
    directorateName:["Directorate Name","የዳይሬክቶሬት ስም"],
    directorateInfo:["Directorate Information","የዳይሬክቶሬት መረጃ"],
    teamInformation:["Team Information","የቡድን መረጃ"],
    members:["Members","አባላት"],
    memberInformation:["Member Information","የአባል መረጃ"],
    responseDate:["Response Date","የተመለበት ቀን"],
    letterSent:["Date of delivery","የተላከበት ቀን"],
    letterReceived:["The date the letter was received","የገባበት ቀን"],
    noOfAttachment:["Number of attachment","የአባሪ ብዛት"],
    incomingLetters:["Incoming Letters","ገቢ ደብዳቤዎች"],
    outgoingLetters:["Outgoing Letters","ወጪ ደብዳቤዎች"],
    internalLetters:["Internal Letters","የውስጥ ደብዳቤዎች"],
    searchByNIMMERA:["Search by letter number (NIMMERA)","በደብዳቤ ቁጥር (ንምራ) ይፈልጉ"],
    searchByLetterNumber:["Search by letter number","በደብዳቤ ቁጥር ይፈልጉ"],
    searchByFrom:["Search by sender name","በላኪ ስም ይፈልጉ"],
    searchByTo:["Search by reciever name","በተቀባይ ስም ይፈልጉ"],
    noIncomingLetter:["No incoming letter available","ምንም ገቢ ደብዳቤ አልተገኘም"],
    noOutgoingLetter:["No outgoing letter available","ምንም ውጪ ደብዳቤ አልተገኘም"],
    noInternalLetter:["No internal letter available","ምንም የውስጥ ደብዳቤ አልተገኘም"],
    noPresystemLetter:["No presystem letter available","ምንም ከሲስተም በፊት የሆነ ደብዳቤ አልተገኘም"],
    noLetter:["No letter found","ደብዳቤው አልተገኘም"],
    editLetter:["Edit Letter","ደብዳቤውን አስተካክል"],
    created:["Created","ተመዝግቧል"],
    forwarded:["Forwarded","ተመርቷል"],
    archivalNumber:["Archival Number","መዝገብ ቁጥር"],
    letterInformation:["Letter Information","የደብዳቤው መረጃ"],
    verifiedCases:["Verified Cases","የተረጋገጡ ጉዳዮች"],
    caseAnalysis:["Case Analysis","የጉዳይ ትንተና"],
    weeklyIncomingLetters:["Weekly incoming letters","በዚ ሳምንት ገቢ የተደረጉ ደብዳቤዎች"],
    weeklyOutgoingLetters:["Weekly outgoing letters","በዚ ሳምንት ወጪ የተደረጉ ደብዳቤዎች"],
    weeklyInternalLetters:["Weekly internal letters","በዚ ሳምንት የውስጥ ደብድቤዎች"],
    weeklyRespondedCases:["Weekly responded cases","በዚ ሳምንት ለማረጋገጫ የመጡ ጉዳዮች"],
    weeklyVerifiedCases:["Weekly verfied cases","በዚ ሳምንት ማረጋገጫ የተሰጣጨው ጉዳዮች"],
    weeklyForwardedLetters:["Weekly Forwarded Letters","በዚ ሳምንት የተመሩ ደብዳቤዎች"],
    forward:["Forward","ምራ"],
    forwardPath:["Forward path","የተመራበት መንገድ"],
    replyPath:["Reply path","የተመለሰበት መንገድ"],
    yearlyLetterAnalysis:["Yearly letter analysis","ዓመታዊ የገቢ ደብዳቤ ትንተና"],
    yearlyOutgoingLetterAnalysis:["Yearly outgoing letter analysis","ዓመታዊ የወጪ ደብዳቤ ትንተና"],
    yearlyInternalLetterAnalysis:["Yearly internal letter analysis","ዓመታዊ የውስጥ ደብዳቤ ትንተና"],
    monthlyIncomingLetterAnalysis:["Monthly letter analysis","ወርሃዊ የገቢ ደብዳቤ ትንተና"],
    monthlyOutgoingLetterAnalysis:["Monthly outgoing letter analysis","ወርሃዊ የወጪ ደብዳቤ ትንተና"],
    monthlyInternalLetterAnalysis:["Monthly internal letter analysis","ወርሃዊ የውስጥ ደብዳቤ ትንተና"],
    letterTypeOption:["Please specify the type of letter, it must be incoming, outgoing  or internal.","እባክዎ የደብዳቤውን አይነት ይግለጹ፣ ገቢ፣ ወጪ ወይም ውስጣዊ መሆን አለበት።"],
    forwardedPathIncoming:["Forwarded path for incoming letter number","የተመራበት መንገድ ለገቢ ደብዳቤ"],
    forwardedPathOutgoing:["Forwarded path for incoming letter number","የተመራበት መንገድ ለወጪ ደብዳቤ"],
    forwardedPathInternal:["Forwarded path for incoming letter number","የተመራበት መንገድ ለውስጥ ደብዳቤ"],
    forwardedPathCutoff:["Forwarded path for presystem letter number","የተመራበት መንገድ ለደብዳቤ (ከሲስተም በፊት)"],
    forwardedDate:["Forwarded Date","የተመራበት ቀን"],
    forwardedTime:["Forwarded Time","የተመራበት ሰዓት"],
    archivalUser:["Archival","የመዝገቤት ተጠቃሚ"],
    noForwardPathLetter:["No forward path available","ይህ ደብዳቤ አልተመራም"],
    forwardPathDetailIncoming:["Forward path information for incoming letter","የተመራበት መንገድ መረጃ ለገቢ ደብዳቤ"],
    forwardPathDetailOutgoing:["Forward path information for outgoing letter","የተመራበት መንገድ መረጃ ለወጪ ደብዳቤ"],
    forwardPathDetailInternal:["Forward path information for internal letter","የተመራበት መንገድ መረጃ ለውስጥ ደብዳቤ"],
    forwardPathDetailPresystem:["Forward path information for presystem letter","የተመራበት መንገድ መረጃ ለደብዳቤ (ከሲስተም በፊት)"],
    createOutgoingLetter:["Create outgoing letter","ወጪ ደብዳቤ ፍጠር"],
    createdLetters:["Created Letters","የተፈጠሩ ደብዳቤዎች"],
    nocreatedLetters:["You haven't created any letters yet","ምንም ደብዳቤ አልተገኝም"],
    approvedBy:["Approved by","የጸደቀው በ"],
    approved:["approved","ጸድቋል"],
    approvedDate:["Approved date","የጸደቀበት ቀን"],
    searchByLetterNumbers:["Search by letter number","በደብዳቤ ቁጥር ይፈልጉ"],
    organizationname:["Organization  name","የተቋም ስም"],
    location:["Location","አድራሻ"],
    add:["Add","ጨምር"],
    remove:["Remove","አስወግድ"],
    mainLetter:["Main Letter","ዋና ደብዳቤ"],
    caseNotUpdated:["This case hasn't been updated","ይህ ጉዳይ መስተካከያ አልተደረገለትም"],
    letterNotUpdated:["This letter hasn't been updated.","ይህ ደብዳቤ መስተካከያ አልተደረገለትም"],
    preview:["Preview","ቅድመ እይታ"],
    noIncomingLetterWeek:["No incoming letter this week","በዚ ሳመንት ምንም ገቢ ደብዳቤ አልተመዘገበም"],
    noOutgoingLetterWeek:["No outgoing letter this week","በዚ ሳመንት ምንም ወጪ ደብዳቤ አልተመዘገበም"],
    noInternalLetterWeek:["No outgoing letter this week","በዚ ሳመንት ምንም የውስጥ ደብዳቤ አልተመዘገበም"],
    noRespondedCasesWeek:["No responded cases this week","በዚ ሳምንት ምንም ለማረጋገጫ የመጣ ጉዳይ የሉም"],
    noVerifiedCasesWeek:["No verified cases this week","በዚ ሳምንት ምንም የተረጋገጠ  ጉዳይ የለም"],
    cutoffLetters:["Presystem  Letters","ከሲስተም በፊት የነበሩ ደብዳቤዎች"],
    notification:["Notification","ኖቲፍክሽን"],
    incomingLetter:["Incoming Letter","ገቢ ደብዳቤ"],
    outgoingLetter:["Outgoing Letter","ወጪ ደብዳቤ"],
    internalLetter:["Internal Letter","የውስጥ ደብዳቤ"],
    noNotifcationFound:["No notification found","ምንም አይነት ኖቲፊኬሽን አልተገኘም"],
    lateIncomingLetters:["Late incoming letters","ያረፈዱ ገቢ ደብዳቤዎች"],
    read:["Read","የተነበበ"],
    unread:["Unread","ያልተነበበ"],
    markasRead:["Mark as read","እንደተነበበ ምልክት አድርግበት"],
    incoming:["Incoming","ገቢ"],
    outgoing:["Outgoing","ወጪ"],
    internal:["Internal","የውስጥ"],
    presystem:["Presystem","ከሲስተም በፊት"],
    noArchivalCategory:["No archival category found","ምንም አይነት የመዝገብ ማህደር አልተገኘም"],
    categoryInformation:["Category Information","የመዝገብ መረጃ"],
    archivedBy:["Archived by","ወደ ማህደሩ የተመዘገበው በ"],
    editCategory:["Edit category","መደቡን አስተካክል"],
    archiveLetter:["Archive letter","ደብዳቤውን ወደ ማህደር ያስገቡ"],
    chooseArchive:["Choose archive category","የመዝገብ መደብ ይምረጡ"],
    reverseOutput:["Reverse","ተመላሽ ይደረግ"],
    previous:["previous","ቀዳሚ ገጽ"],
    next:["next","ቀጣይ ገጽ"],
    page:["page","ገጽ"],
    removeStampFromPage:["remove stamp from page","ማህተምን ከገጽ ያስወግዱ"],
    placeStampOnPage:["place stamp on page","ማህተም በገጽ ላይ ያስቀምጡ"],
    stampPlacement:["Stamp Placement","ማህተም አድርግ"],
    reloadPDF:["Reload PDF","ሪሎድ ያርጉ"],
    
  

    
}